export const icons = {
    main: <i className="fa fa-fw fa-envelope"></i>,
    home: <i className="fa fa-fw fa-home"></i>,
    about: <i className="fa fa-fw fa-info"></i>,
    join: <i className="fa fa-fw fa-users"></i>,
    contact: <i className="fa fa-fw fa-envelope"></i>,
    notification: <i className="fa fa-fw fa-bell"></i>,
    profile: <i className="fa fa-fw fa-user"></i>,
    logout: <i className="fa fa-fw fa-sign-out"></i>,
    login: <i className="fa fa-fw fa-sign-in"></i>,
    signup: <i className="fa fa-fw fa-user-plus"></i>,
    edit: <i className="fa fa-fw fa-edit"></i>,
    delete: <i className="fa fa-fw fa-trash"></i>,
    add: <i className="fa fa-fw fa-plus"></i>,
    whatsapp: <i className="fa fa-fw fa-whatsapp"></i>,
    call: <i className="fa fa-fw fa-phone"></i>,
    documents: <i className="fa fa-fw fa-file"></i>,
    gallery: <i className="fa fa-fw fa-image"></i>,
    achievements: <i className="fa fa-fw fa-trophy"></i>,
};
